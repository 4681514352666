import React from 'react';

const FBRevenueCard = ({ fbRevenue, error }) => {
  if (error) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-2">F&B Revenue</h2>
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  const formattedRevenue = fbRevenue
    ? `€${Math.round(fbRevenue).toLocaleString('de-DE')}`
    : 'N/A';

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-2">F&B Revenue</h2>
      <p className="text-3xl font-bold text-purple-600">{formattedRevenue}</p>
    </div>
  );
};

export default FBRevenueCard;
