import React from 'react';

const OccupancyCard = ({ title, value, suffix = '%' }) => {
  // Convert to percentage and format to whole number
  const formattedValue = value 
    ? Math.round(Number(value) * 100) 
    : 0;

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-2 text-gray-700">{title}</h2>
      <p className="text-3xl font-bold text-blue-600">{formattedValue}{suffix}</p>
    </div>
  );
};

export default OccupancyCard;