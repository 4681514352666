import React, { useState, useRef, useEffect } from 'react';

const KPICard = ({ title, value, error, colorClass, infoText }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipDirection, setTooltipDirection] = useState('up');
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const rect = tooltipRef.current.getBoundingClientRect();
      if (rect.top < 10) {
        setTooltipDirection('right');
      } else {
        setTooltipDirection('up');
      }
    }
  }, [showTooltip]);

  if (error) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-2">{title}</h2>
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  const formattedValue = typeof value === 'number' ? `€${value.toFixed(0)}` : 'N/A';

  return (
    <div className="bg-white shadow rounded-lg p-6 relative">
      <div className="flex items-center mb-2">
        <h2 className="text-xl font-semibold">{title}</h2>
        {infoText && (
          <div className="relative ml-2">
            <span
              className="inline-flex items-center justify-center w-5 h-5 border border-black rounded-full text-black text-xs cursor-pointer"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              i
            </span>
            {showTooltip && (
              <div
                ref={tooltipRef}
                className={`absolute p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10 w-48 ${
                  tooltipDirection === 'up'
                    ? 'bottom-full left-1/2 transform -translate-x-1/2 mb-2'
                    : 'top-0 left-full ml-2'
                }`}
              >
                {infoText}
              </div>
            )}
          </div>
        )}
      </div>
      <p className={`text-3xl font-bold ${colorClass}`}>{formattedValue}</p>
    </div>
  );
};

export default KPICard;
