import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const RevenueChart = ({ revenueData }) => {
  const [selectedDataPoints, setSelectedDataPoints] = useState(['Logis', 'FB', 'Extra']);

  // Check if revenueData is an array and not empty
  if (!Array.isArray(revenueData) || revenueData.length === 0) {
    return <div>No data available for the chart</div>;
  }

  // Format the data to display only year and month
  const formattedData = revenueData.map(item => ({
    ...item,
    'year_month': item['year_month'] // Use 'year_month' directly, no need for substring
  }));

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'Total') {
      setSelectedDataPoints(checked ? ['Total'] : ['Logis', 'FB', 'Extra']);
    } else {
      setSelectedDataPoints(prev => {
        const newSelection = checked
          ? [...prev.filter(item => item !== 'Total'), name]
          : prev.filter(item => item !== name);
        return newSelection.length === 0 ? ['Total'] : newSelection;
      });
    }
  };

  const dataPoints = [
    { key: 'Total', color: '#8884d8' }, // Add Total with a new color
    { key: 'Logis', color: '#82ca9d' },
    { key: 'FB', color: '#ffc658' },
    { key: 'Extra', color: '#ff8042' }
  ];

  // Function to format numbers with dot as thousand separator and no decimals
  const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE', { 
      maximumFractionDigits: 0,
      useGrouping: true
    }).format(number);
  };

  return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        gap: '15px'
      }}>
        {dataPoints.map(({ key, color }) => (
          <label
            key={key}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
              userSelect: 'none',
              padding: '8px 12px',
              borderRadius: '20px',
              backgroundColor: selectedDataPoints.includes(key) ? color : '#f0f0f0',
              color: selectedDataPoints.includes(key) ? 'white' : 'black',
              transition: 'all 0.3s ease'
            }}
          >
            <input
              type="checkbox"
              name={key}
              checked={selectedDataPoints.includes(key)}
              onChange={handleCheckboxChange}
              style={{ display: 'none' }}
            />
            {key}
          </label>
        ))}
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={formattedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year_month" />
          <YAxis tickFormatter={formatNumber} />
          <Tooltip 
            formatter={(value, name) => [formatNumber(value), name]}
            labelFormatter={(label) => `Year-Month: ${label}`}
          />
          {dataPoints.map(({ key, color }) => 
            selectedDataPoints.includes(key) && (
              <Bar key={key} dataKey={key} stackId="a" fill={color} />
            )
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RevenueChart;