import React from 'react';

const Rooms = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Rooms</h2>
      {/* Add your table or detailed data here */}
    </div>
  );
};

export default Rooms;
