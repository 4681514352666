import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const YearOverYearRevenueChart = ({ revenueData }) => {
  // Function to format numbers with dot as thousand separator and no decimals
  const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE', { 
      maximumFractionDigits: 0,
      useGrouping: true
    }).format(number);
  };

  // Process the data to create year-over-year comparison
  const processedData = revenueData.reduce((acc, curr) => {
    const currentYear = parseInt(curr.year);
    const previousYear = currentYear - 1;
    
    const existingEntry = acc.find(item => item.month === curr.month);
    if (existingEntry) {
      existingEntry[`${currentYear}`] = curr.Total;
    } else {
      acc.push({
        month: curr.month,
        [`${currentYear}`]: curr.Total,
        [`${previousYear}`]: 0 // Initialize previous year's data
      });
    }
    return acc;
  }, []);

  // Sort the processed data by month
  processedData.sort((a, b) => parseInt(a.month) - parseInt(b.month));

  const years = [...new Set(revenueData.map(item => item.year_month.split('-')[0]))].sort();

  return (
    <div>
      <h2>Year-over-Year Revenue Comparison</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis tickFormatter={formatNumber} />
          <Tooltip 
            formatter={(value, name) => [formatNumber(value), `Year ${name}`]}
            labelFormatter={(label) => `Month: ${label}`}
          />
          <Legend />
          {years.map((year, index) => (
            <Bar key={year} dataKey={year} fill={index % 2 === 0 ? "#8884d8" : "#82ca9d"} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default YearOverYearRevenueChart;
