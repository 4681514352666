import React, { useState, useEffect, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import { getReservations } from '../services/api';

const Bookings = () => {
  const [reservations, setReservations] = useState([]);
  const [filteredReservations, setFilteredReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');

  const fetchReservations = async () => {
    try {
      setIsLoading(true);
      const data = await getReservations();
      setReservations(data);
      setFilteredReservations(data);
      setIsLoading(false);
    } catch (err) {
      console.error('Error in fetchReservations:', err);
      setError('Failed to fetch reservations: ' + err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReservations();

    window.addEventListener('dateRangeChanged', fetchReservations);

    return () => {
      window.removeEventListener('dateRangeChanged', fetchReservations);
    };
  }, []);

  // Efficiently compute unique statuses
  const uniqueStatuses = useMemo(() => {
    const statuses = new Set(reservations.map(r => r.status));
    return ['', ...Array.from(statuses)].sort();
  }, [reservations]);

  useEffect(() => {
    const filtered = statusFilter
      ? reservations.filter(r => r.status === statusFilter)
      : reservations;
    setFilteredReservations(filtered);
  }, [statusFilter, reservations]);

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  // New constant for row height
  const ROW_HEIGHT = 48;
  const TABLE_HEIGHT = 600; // Increased from 400 to 600

  const columnWidths = {
    date: 'min-w-[120px] w-[15%]',
    status: 'min-w-[100px] w-[10%]',
    number: 'min-w-[60px] w-[7%]',
    price: 'min-w-[80px] w-[10%]',
  };

  const Row = ({ index, style }) => {
    const reservation = filteredReservations[index];
    return (
      <div style={style} className="flex border-b border-gray-200 hover:bg-gray-100">
        <div className={`py-3 px-2 text-left whitespace-nowrap font-medium ${columnWidths.date}`}>{reservation.datumvon}</div>
        <div className={`py-3 px-2 text-left whitespace-nowrap font-medium ${columnWidths.date}`}>{reservation.datumbis}</div>
        <div className={`py-3 px-2 text-left whitespace-nowrap font-medium ${columnWidths.status}`}>{reservation.status}</div>
        <div className={`py-3 px-2 text-center font-medium ${columnWidths.number}`}>{reservation.anzerw}</div>
        <div className={`py-3 px-2 text-center font-medium ${columnWidths.number}`}>{reservation.anzkin1}</div>
        <div className={`py-3 px-2 text-center font-medium ${columnWidths.number}`}>{reservation.naechte}</div>
        <div className={`py-3 px-2 text-right font-medium ${columnWidths.price}`}>{reservation.logis.toFixed(2)}</div>
        <div className={`py-3 px-2 text-right font-medium ${columnWidths.price}`}>{reservation.fb.toFixed(2)}</div>
        <div className={`py-3 px-2 text-right font-medium ${columnWidths.price}`}>{reservation.extras.toFixed(2)}</div>
        <div className={`py-3 px-2 text-right font-medium ${columnWidths.price}`}>{reservation.revenue.toFixed(2)}</div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-full">
      <h2 className="text-4xl font-bold mb-6 text-gray-800">Bookings</h2>
      
      {/* Display count of items */}
      <div className="mb-4 text-lg text-gray-700">
        Displaying {filteredReservations.length} of {reservations.length} bookings
      </div>

      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <div className="min-w-[1000px]"> {/* Set a minimum width for the table */}
          <div className="bg-gray-200 text-gray-800 uppercase text-sm leading-normal">
            <div className="flex">
              <div className={`py-3 px-2 text-left font-bold ${columnWidths.date}`}>Check-in Date</div>
              <div className={`py-3 px-2 text-left font-bold ${columnWidths.date}`}>Check-out Date</div>
              <div className={`py-3 px-2 text-left font-bold ${columnWidths.status}`}>Status</div>
              <div className={`py-3 px-2 text-center font-bold ${columnWidths.number}`}>Adults</div>
              <div className={`py-3 px-2 text-center font-bold ${columnWidths.number}`}>Children</div>
              <div className={`py-3 px-2 text-center font-bold ${columnWidths.number}`}>Nights</div>
              <div className={`py-3 px-2 text-right font-bold ${columnWidths.price}`}>Lodging</div>
              <div className={`py-3 px-2 text-right font-bold ${columnWidths.price}`}>F&B</div>
              <div className={`py-3 px-2 text-right font-bold ${columnWidths.price}`}>Extras</div>
              <div className={`py-3 px-2 text-right font-bold ${columnWidths.price}`}>Revenue</div>
            </div>
            <div className="flex">
              <div className={columnWidths.date}></div>
              <div className={columnWidths.date}></div>
              <div className={`py-2 px-2 ${columnWidths.status}`}>
                <select
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  className="w-full bg-gray-100 text-gray-800 border border-gray-300 rounded py-1 px-2 text-sm leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  {uniqueStatuses.map(status => (
                    <option key={status} value={status}>
                      {status || 'All Statuses'}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${columnWidths.number} ${columnWidths.number} ${columnWidths.number} ${columnWidths.price} ${columnWidths.price} ${columnWidths.price} ${columnWidths.price}`}></div>
            </div>
          </div>
          <List
            height={TABLE_HEIGHT}
            itemCount={filteredReservations.length}
            itemSize={ROW_HEIGHT}
            width="100%"
          >
            {Row}
          </List>
        </div>
      </div>
    </div>
  );
};

export default Bookings;