import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const RoomsChart = ({ roomKPIsPerMonth }) => {
  if (!roomKPIsPerMonth || typeof roomKPIsPerMonth !== 'object') {
    return <div>No data available</div>;
  }

  const dates = Object.keys(roomKPIsPerMonth);
  const occupiedRooms = Object.values(roomKPIsPerMonth).map(item => Math.round(item.zi_belegt));
  const freeRooms = Object.values(roomKPIsPerMonth).map(item => Math.round(item.zi_frei));
  const totalRooms = occupiedRooms.map((occupied, index) => Math.round(occupied + freeRooms[index]));

  const maxTotalRooms = Math.max(...totalRooms);
  const yAxisMax = Math.ceil(maxTotalRooms * 1.1); // 10% padding on top

  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Occupied Rooms',
        data: occupiedRooms,
        backgroundColor: 'rgba(75, 192, 192, 0.8)',
      },
      {
        label: 'Free Rooms',
        data: freeRooms,
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Room Occupancy per Date'
      },
      annotation: {
        annotations: [
          ...dates.map((date, index) => ({
            type: 'label',
            xValue: index,
            yValue: totalRooms[index],
            content: totalRooms[index].toString(),
            backgroundColor: 'rgba(0,0,0,0.7)',
            color: 'white',
            font: {
              size: 12,
            },
            padding: 4,
          })),
          ...dates.map((date, index) => ({
            type: 'label',
            xValue: index,
            yValue: occupiedRooms[index] / 2,
            content: occupiedRooms[index].toString(),
            backgroundColor: 'rgba(75, 192, 192, 0.8)',
            color: 'white',
            font: {
              size: 12,
            },
            padding: 4,
          })),
          ...dates.map((date, index) => ({
            type: 'label',
            xValue: index,
            yValue: occupiedRooms[index] + freeRooms[index] / 2,
            content: freeRooms[index].toString(),
            backgroundColor: 'rgba(255, 99, 132, 0.8)',
            color: 'white',
            font: {
              size: 12,
            },
            padding: 4,
          })),
        ],
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Number of Rooms'
        },
        beginAtZero: true,
        max: yAxisMax,
      }
    }
  };

  return <div style={{ height: '400px' }}><Bar data={data} options={options} /></div>;
};

export default RoomsChart;
