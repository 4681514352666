import React from 'react';

const TotalRevenueCard = ({ totalRevenue, error }) => {
  if (error) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-2">Total Revenue</h2>
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  const formattedRevenue = totalRevenue 
  
  ? `€${Math.round(totalRevenue).toLocaleString('de-DE')}`
  : 'N/A';

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-2">Total Revenue</h2>
      <p className="text-3xl font-bold text-green-600">{formattedRevenue}</p>
    </div>
  );
};

export default TotalRevenueCard;