import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRevenueTotals, getAverageRoomsOccupied, getAverageBedsOccupied, getRoomsKPIs, getRevenuePerProfitCenterSum, getADR, getRevPAR, getRoomKPIsPerMonth, getRevenuePerProfitCenterPerMonth, getRevPOR, getRevenueSumPerPerson } from '../services/api';
import TotalRevenueCard from './TotalRevenueCard';
import LogisRevenueCard from './LogisRevenueCard';
import FBRevenueCard from './FBRevenueCard';
import ExtraRevenueCard from './ExtraRevenueCard';
import OccupancyCard from './OccupancyCard';
import RevenueChart from './RevenueChart';
import KPICard from './KPICard';
import YearOverYearRevenueChart from './YearOverYearRevenueChart';
import RoomsChart from './RoomsChart';

const Dashboard = () => {
  const navigate = useNavigate();
  const [averageRoomsOccupied, setAverageRoomsOccupied] = useState(null);
  const [averageBedsOccupied, setAverageBedsOccupied] = useState(null);
  const [adr, setAdr] = useState(null);
  const [revpar, setRevpar] = useState(null);
  const [revenuePerProfitCenterSum, setRevenuePerProfitCenterSum] = useState(null);
  const [roomKPIsPerMonth, setRoomKPIsPerMonth] = useState(null);
  const [revenuePerProfitCenterPerMonth, setRevenuePerProfitCenterPerMonth] = useState([]);
  const [revpor, setRevpor] = useState(null);
  const [revenueSumPerPerson, setRevenueSumPerPerson] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [revenueTotals, setRevenueTotals] = useState(null);
  const [roomsKPIs, setRoomsKPIs] = useState(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const newErrors = {};

    try {
      const revenueSumPerPersonData = await getRevenueSumPerPerson();
      console.log('Revenue Sum Per Person Data:', revenueSumPerPersonData);
      setRevenueSumPerPerson(revenueSumPerPersonData);
    } catch (error) {
      console.error('Error fetching revenue sum per person:', error);
      newErrors.revenueSumPerPerson = 'Failed to fetch revenue sum per person';
    }

    const fetchAndSetData = async (apiCall, setter, errorKey) => {
      try {
        const response = await apiCall();
        setter(response);
      } catch (error) {
        console.error(`Error fetching ${errorKey}:`, error);
        newErrors[errorKey] = `Failed to fetch ${errorKey}`;
      }
    };

    await Promise.all([
      fetchAndSetData(getRevenueTotals, setRevenueTotals, 'revenueTotals'),
      fetchAndSetData(getRevenuePerProfitCenterSum, setRevenuePerProfitCenterSum, 'revenuePerProfitCenterSum'),
      fetchAndSetData(getRevenuePerProfitCenterPerMonth, setRevenuePerProfitCenterPerMonth, 'revenuePerProfitCenterPerMonth'),
      fetchAndSetData(getRoomsKPIs, setRoomsKPIs, 'roomsKPIs'),
      fetchAndSetData(getAverageRoomsOccupied, setAverageRoomsOccupied, 'averageRoomsOccupied'),
      fetchAndSetData(getAverageBedsOccupied, setAverageBedsOccupied, 'averageBedsOccupied'),
      fetchAndSetData(getRoomKPIsPerMonth, data => setRoomKPIsPerMonth(data.room_kpis_per_month), 'roomKPIsPerMonth'),
      fetchAndSetData(getADR, setAdr, 'adr'),
      fetchAndSetData(getRevPAR, setRevpar, 'revpar'),
      fetchAndSetData(getRevPOR, setRevpor, 'revpor'),
    ]);

    setErrors(newErrors);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, redirecting to login');
      navigate('/login');
      return;
    }

    const startDate = localStorage.getItem('startDate');
    const endDate = localStorage.getItem('endDate');
    if (startDate && endDate) {
      setDateRange({ startDate, endDate });
    }

    fetchData();
  }, [navigate, fetchData]);

  useEffect(() => {
    const handleDateRangeChange = () => {
      fetchData();
    };

    window.addEventListener('dateRangeChanged', handleDateRangeChange);

    return () => {
      window.removeEventListener('dateRangeChanged', handleDateRangeChange);
    };
  }, [fetchData]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header box */}
      {/* Display selected date range */}
          <div className="mb-4 p-4 bg-white rounded shadow">
            <h2 className="text-lg font-semibold mb-2">Selected Date Range:</h2>
            <p>{dateRange.startDate} - {dateRange.endDate}</p>
        </div>  
        {/* Main content */}
        <div> 
          {/* 
          ############################
          ############################
          Revenues
          ############################
          ############################
          */}
          <h1 className="text-3xl font-bold text-gray-800 mb-6 pb-2 border-b-2 border-gray-200">
            Revenues
          </h1>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            <TotalRevenueCard 
              totalRevenue={revenuePerProfitCenterSum?.revenue_per_profitcenter?.total} 
            />
            <LogisRevenueCard 
              logisRevenue={revenuePerProfitCenterSum?.revenue_per_profitcenter?.lognetto} 
              error={errors.revenuePerProfitCenter} 
            />
            <FBRevenueCard 
              fbRevenue={revenuePerProfitCenterSum?.revenue_per_profitcenter?.fbnetto} 
              error={errors.revenuePerProfitCenter} 
            />
            <ExtraRevenueCard 
              extraRevenue={revenuePerProfitCenterSum?.revenue_per_profitcenter?.extranetto} 
              error={errors.revenuePerProfitCenter} 
            />
          </div>
          <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h3 className="text-xl font-semibold mb-4">Revenue Over Time</h3>
            {errors.revenuePerProfitCenterPerMonth ? (
              <p className="text-red-500">{errors.revenuePerProfitCenterPerMonth}</p>
            ) : (
              <RevenueChart revenueData={revenuePerProfitCenterPerMonth} />
            )}
          </div>

          <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h3 className="text-xl font-semibold mb-4">Year-over-Year Revenue Comparison</h3>
            {errors.revenuePerProfitCenterPerMonth ? (
              <p className="text-red-500">{errors.revenuePerProfitCenterPerMonth}</p>
            ) : (
              <YearOverYearRevenueChart revenueData={revenuePerProfitCenterPerMonth} />
            )}
          </div>

          {/* 
          ############################
          ############################
          Occupancy
          ############################
          ############################
          */}

          <h2 className="text-3xl font-bold text-gray-800 mb-6 pb-2 border-b-2 border-gray-200">
            Occupancy
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <OccupancyCard 
              title="Room Occupancy"
              value={averageRoomsOccupied?.average_rooms_occupied}
            />
            <OccupancyCard 
              title="Bed Occupancy"
              value={averageBedsOccupied?.average_beds_occupied}
            />
          </div>
          
          <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h3 className="text-xl font-semibold mb-4">Rooms Occupied Over Time</h3>
            {errors.roomKPIsPerMonth ? (
              <p className="text-red-500">{errors.roomKPIsPerMonth}</p>
            ) : (
              <RoomsChart roomKPIsPerMonth={roomKPIsPerMonth} />
            )}
          </div>

          {/* 
          ############################
          ############################
          KPIs
          ############################
          ############################
          */}

          <h2 className="text-3xl font-bold text-gray-800 mt-8 mb-6 pb-2 border-b-2 border-gray-200">
            KPIs
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <KPICard
              title="ADR"
              value={adr?.average_daily_rate_ADR}
              error={errors.adr}
              colorClass="text-blue-600"
              infoText="The Average Daily Rate (ADR) is the average revenue generated per available room in a given period. It is calculated by dividing the total revenue by the number of available rooms."
            />
            <KPICard
              title="RevPAR"
              value={revpar?.revenue_per_available_room_RevPAR}
              error={errors.revpar}
              colorClass="text-green-600"
              infoText="The Revenue Per Available Room (RevPAR) is the total revenue generated per available room in a given period. It is calculated by dividing the total revenue by the number of available rooms."
            />
            <KPICard
              title="RevPOR"
              value={revpor?.revenue_per_occupied_room_RevPOR}
              error={errors.revpor}
              colorClass="text-purple-600"
              infoText="The Revenue Per Occupied Room (RevPOR) is the total revenue generated per occupied room in a given period. It is calculated by dividing the total revenue by the number of occupied rooms."
            />
            <KPICard
              title="Total Revenue per Person"
              value={revenueSumPerPerson?.revenue_sum_per_person?.total_revenue_per_person}
              error={errors.revenueSumPerPerson}
              colorClass="text-orange-600"
              infoText="The Revenue Per Person is the total revenue generated per person in a given period. It is calculated by dividing the total revenue by the number of guests."
            />
            <KPICard
              title="Logis Revenue per Person"
              value={revenueSumPerPerson?.revenue_sum_per_person?.logis_revenue_per_person}
              error={errors.revenueSumPerPerson}
              colorClass="text-yellow-600"
              infoText="The Logis Revenue Per Person is the revenue generated from accommodation per person in a given period. It is calculated by dividing the logis revenue by the number of guests."
            />
            <KPICard
              title="F&B Revenue per Person"
              value={revenueSumPerPerson?.revenue_sum_per_person?.fb_revenue_per_person}
              error={errors.revenueSumPerPerson}
              colorClass="text-red-600"
              infoText="The F&B (Food and Beverage) Revenue Per Person is the revenue generated from food and beverage sales per person in a given period. It is calculated by dividing the F&B revenue by the number of guests."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
