import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production'
  ? '/api'                    // Production: use relative path with /api prefix
  : 'http://localhost:8000';  // Development: direct to FastAPI

console.log('Current API URL:', API_URL);

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('No token found in localStorage');
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data);
    throw new Error(error.response.data.detail || 'An error occurred');
  } else if (error.request) {
    console.error('API Error: No response received');
    throw new Error('No response received from server');
  } else {
    console.error('API Error:', error.message);
    throw error;
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0]; // This will return YYYY-MM-DD
};

const addDateParams = (params = {}) => {
  const startDate = localStorage.getItem('startDate');
  const endDate = localStorage.getItem('endDate');
  if (startDate && endDate) {
    return { 
      ...params, 
      start_date: formatDate(startDate), 
      end_date: formatDate(endDate) 
    };
  }
  return params;
};

export const login = async (username, password) => {
  const response = await api.post('/token', new URLSearchParams({
    username,
    password,
    grant_type: 'password'
  }), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  });
  return response;
};

export const getRevenueTotals = async () => {
  const response = await api.get('/revenue_totals', { params: addDateParams() });
  return response.data;
};

export const getAverageRoomsOccupied = async () => {
  const response = await api.get('/average_rooms_occupied', { params: addDateParams() });
  return response.data;
};

export const getRoomsKPIs = async () => {
  const response = await api.get('/rooms_kpis', { params: addDateParams() });
  return response.data;
};

export const getRoomKPIsPerMonth = async () => {
  const response = await api.get('/room_kpis_per_month', { params: addDateParams() });
  return response.data;
};

export const getAverageBedsOccupied = async () => {
  const response = await api.get('/average_beds_occupied', { params: addDateParams() });
  return response.data;
};

export const getRevenuePerProfitCenterSum = async () => {
  const response = await api.get('/revenue_per_profitcenter_sum', { params: addDateParams() });
  return response.data;
};

export const getADR = async () => {
  try {
    const response = await api.get('/ADR', { params: addDateParams() });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getRevPAR = async () => {
  try {
    const response = await api.get('/RevPAR', { params: addDateParams() });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getRevPOR = async () => {
  try {
    const response = await api.get('/RevPOR', { params: addDateParams() });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getRevenuePerProfitCenterPerMonth = async () => {
  try {
    const response = await api.get('/revenue_per_profitcenter_per_month', { params: addDateParams() });
    return response.data.revenue_per_profitcenter_per_month;
  } catch (error) {
    handleApiError(error);
  }
};

export const getReservations = async () => {
  try {
    const response = await api.get('/reservations', { params: addDateParams() });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getRevenueSumPerPerson = async () => {
  try {
    const response = await api.get('/revenue_sum_per_person', { params: addDateParams() });
    console.log('API Response for getRevenueSumPerPerson:', response.data);
    return response.data;
  } catch (error) {
    console.error('API Error in getRevenueSumPerPerson:', error);
    handleApiError(error);
  }
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // Redirect to login page
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
