import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaHome, FaBed, FaCalendarAlt, FaUsers, FaClipboardList } from 'react-icons/fa';

function Layout() {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });

  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);

  const toggleSidebar = useCallback(() => {
    setSidebarExpanded((prev) => !prev);
  }, []);

  const startDateInputRef = useRef(null);

  const toggleCalendar = useCallback((event) => {
    const rect = event?.currentTarget?.getBoundingClientRect() || { bottom: 0, left: 0 };
    setCalendarPosition({
      top: rect.bottom + window.scrollY,
      left: sidebarExpanded ? rect.left : rect.right
    });
    setIsCalendarOpen((prevState) => {
      if (!prevState) {
        setTimeout(() => startDateInputRef.current?.focus(), 0);
      }
      return !prevState;
    });
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  }, [sidebarExpanded, startDate, endDate]);

  const handleStartDateChange = (e) => {
    setTempStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setTempEndDate(e.target.value);
  };

  const handleApplyDateRange = useCallback(() => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    localStorage.setItem('startDate', tempStartDate);
    localStorage.setItem('endDate', tempEndDate);
    setIsCalendarOpen(false);
    window.dispatchEvent(new Event('dateRangeChanged'));
  }, [tempStartDate, tempEndDate]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'd' && !isCalendarOpen) {
      toggleCalendar();
    } else if (event.key === 'Enter' && isCalendarOpen) {
      handleApplyDateRange();
    } else if (event.key === 'Escape' && isCalendarOpen) {
      setIsCalendarOpen(false);
    } else if (event.key === 'b') {
      toggleSidebar();
    }
  }, [isCalendarOpen, toggleCalendar, handleApplyDateRange, toggleSidebar]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const storedStartDate = localStorage.getItem('startDate');
    const storedEndDate = localStorage.getItem('endDate');
    if (storedStartDate && storedEndDate) {
      setStartDate(storedStartDate);
      setEndDate(storedEndDate);
    } else {
      // Set default dates if not stored
      const defaultStartDate = new Date().toISOString().split('T')[0];
      const defaultEndDate = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0];
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      localStorage.setItem('startDate', defaultStartDate);
      localStorage.setItem('endDate', defaultEndDate);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isCalendarOpen && !event.target.closest('.calendar-container')) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCalendarOpen]);

  return (
    <div className="flex h-screen bg-gray-100">
      <aside className={`bg-white shadow-md transition-all duration-300 ease-in-out ${sidebarExpanded ? 'w-64' : 'w-16'} overflow-hidden`}>
        <div className="flex justify-end p-4">
          <button
            onClick={toggleSidebar}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            {sidebarExpanded ? <FaChevronLeft className="text-xl" /> : <FaChevronRight className="text-xl" />}
          </button>
        </div>
        
        {/* Date Range Selector */}
        <div className="px-4 py-2 border-b border-gray-200">
          <button
            onClick={toggleCalendar}
            className={`flex items-center text-sm text-gray-700 hover:bg-gray-100 rounded p-2 w-full ${sidebarExpanded ? '' : 'justify-center'}`}
          >
            <div className="w-6 h-6 flex items-center justify-center flex-shrink-0">
              <FaCalendarAlt className="text-2xl" />
            </div>
            {sidebarExpanded && (
              <span className="ml-2 truncate">
                {startDate} - {endDate}
              </span>
            )}
          </button>
        </div>

        <nav>
          <ul className="space-y-2">
            <li>
              <Link to="/" className={`flex items-center px-4 py-2 text-gray-700 hover:bg-gray-200 ${sidebarExpanded ? '' : 'justify-center'}`}>
                <div className="w-6 h-6 flex items-center justify-center">
                  <FaHome className="text-2xl" />
                </div>
                {sidebarExpanded && <span className="ml-2">Dashboard</span>}
              </Link>
            </li>
            <li>
              <Link to="/rooms" className={`flex items-center px-4 py-2 text-gray-700 hover:bg-gray-200 ${sidebarExpanded ? '' : 'justify-center'}`}>
                <div className="w-6 h-6 flex items-center justify-center">
                  <FaBed className="text-2xl" />  
                </div>
                {sidebarExpanded && <span className="ml-2">Rooms</span>}
              </Link>
            </li>
            <li>
              <Link to="/bookings" className={`flex items-center px-4 py-2 text-gray-700 hover:bg-gray-200 ${sidebarExpanded ? '' : 'justify-center'}`}>
                <div className="w-6 h-6 flex items-center justify-center">
                  <FaClipboardList className="text-2xl" />
                </div>
                {sidebarExpanded && <span className="ml-2">Bookings</span>}
              </Link>
            </li>
            <li>
              <Link to="/guests" className={`flex items-center px-4 py-2 text-gray-700 hover:bg-gray-200 ${sidebarExpanded ? '' : 'justify-center'}`}>
                <div className="w-6 h-6 flex items-center justify-center">
                  <FaUsers className="text-2xl" />
                </div>
                {sidebarExpanded && <span className="ml-2">Guests</span>}
              </Link>
            </li>
          </ul>
        </nav>
      </aside>
      <main className="flex-1 overflow-y-auto p-8">
        <Outlet />
      </main>
      {isCalendarOpen && (
        <div 
          className="calendar-container absolute bg-white shadow-lg rounded-lg p-4 z-50"
          style={{ top: `${calendarPosition.top}px`, left: `${calendarPosition.left}px` }}
        >
          <div className="mb-4 text-sm text-gray-600">
            Current selection: {startDate} - {endDate}
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              ref={startDateInputRef}
              type="date"
              value={tempStartDate}
              onChange={handleStartDateChange}
              onKeyDown={(e) => e.key === 'Enter' && handleApplyDateRange()}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              value={tempEndDate}
              onChange={handleEndDateChange}
              onKeyDown={(e) => e.key === 'Enter' && handleApplyDateRange()}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <button
            onClick={handleApplyDateRange}
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
}

export default Layout;
